$white: #fff;
$light-grey: #f7f7fb;
$grey: #f2f2f2;
$page-bg: $light-grey;
$grey-text: rgba(58, 68, 106, .54);
$divider: #dfe2ed;
$warn: #f44336;
$bg-light-grey: #f8f8fc;
$black: #0e1013;
$border: $divider;
$border-valid: #3e64ff;
$grey-text-light: #8791b4;
$grey-text-dark: #373c49;
$black-text-light: #404451;
