.d-flex {
    display: flex !important;
}

.fx-layout-align-l-c {
    justify-content: left;
    align-items: center;
    display: flex;
}

.fx-layout-align-c-c {
    justify-content: center;
    align-items: center;
    display: flex;
}

.fx-layout-align-sb-n {
    justify-content: space-between;
    align-items: none;
    display: flex;
}

.fx-layout-align-sb-c {
    justify-content: start;
    align-items: center;
    display: flex;
}

.fx-layout-c {
    flex-direction: column;
    display: flex;
}

.fx-layout-r {
    flex-direction: row;
    display: flex !important;
}

.fx-layout-c-r {
    flex-direction: column-reverse;
    display: flex;
}

.fx-layout-r-r {
    flex-direction: row-reverse;
    display: flex;
}

.fx-flex-auto {
    display: flex;
    flex: 1 1 auto;
}

.fx-flex-50 {
    display: flex;
    flex: 50px;
}

.fx-flex-100 {
    display: flex;
    flex: 100px;
}

.fx-flex-none {
    display: flex;
    flex: none;
}

.fx-flex-auto {
    display: flex;
    flex: auto;
}

.fx-flex-fill {
    display: flex;
    flex: 100%;
}

/* xs */
@media screen and (max-width: 599px) {
}

/* sm */
@media screen and (min-width: 600px) and (max-width: 959px) {
}

/* md */
@media screen and (min-width: 960px) and (max-width: 1279px) {
}

/* lg */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
}

/* xl */
@media screen and (min-width: 1920px) {
}

/* lt-sm */
@media screen and (max-width: 599px) {
}

/* lt-md */
@media screen and (max-width: 959px) {
}

/* lt-lg */
@media screen and (max-width: 1279px) {
}

/* lt-xl */
@media screen and (max-width: 1919px) {
}

/* gt-xs */
@media screen and (min-width: 600px) {
}

/* gt-sm */
@media screen and (min-width: 960px) {
}

/* gt-md */
@media screen and (min-width: 1280px) {
}

/* gt-md */
@media screen and (min-width: 1920px) {
}
