@import 'consts/colors';

body {
  background-color: $page-bg;
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

button {
  cursor: pointer;
  outline: none;
}

input {
  font-family: 'Roboto', sans-serif;
}
