/* Portal Global  */
@import 'typography';
@import 'palette';
@import 'theming';
@import 'menus';
@import 'backgrounds';
@import 'inputs';
@import 'toolbar';
@import 'scrollbars';
@import 'rtl';
@import 'theme-icon';
@import 'loader';
@import 'ie';


/* Layout Components */
@import '../app/layouts/components/menu-sidenav/_menu-sidenav-theme';
@import '../app/layouts/components/layout-loader/_layout-loader-theme.scss';
@import '../app/layouts/components/top-horizontal-menu/_top-horizontal-menu-theme';

/* Error pages */
@import '../app/external-pages/error-404/_error-404-theme';

@mixin portal-theme($theme, $typography) {
  /* Portal Typography */
  @include portal-typography();

  /* Portal Loader */
  @include portal-loader($theme);

  /* Menu Utility Classes */
  @include portal-menus($theme);

  /* Input Classes */
  @include portal-inputs();

  /* Toolbar Classes */
  @include portal-toolbar($theme);

  /* Coloured background classes */
  @include portal-backgrounds($theme);

  /* Hide scrollbars classes */
  @include portal-scrollbars();

  /* RTL */
  @include portal-rtl();

  /* Portal Layouts */

  /* Portal Layout Component Themes */

  @include portal-menu-sidenav-theme($theme, $typography);
  @include portal-layout-loader-theme($theme);
  @include portal-top-horizontal-menu-theme($theme);

  /* Error Pages Themes */
  @include portal-error-404-theme($theme);
}
