@use '@angular/material' as mat;
@import 'consts/colors';

/**
 * Copy of material designs theme function, but this allows us to set the app-bar color
 */
@function portal-light-theme($primary, $accent, $warn: mat.define-palette(mat.$red-palette), $app-bar-background: white, $app-bar-color: black) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: (
      base:              black,
      divider:           $divider,
      dividers:          $black-12-opacity,
      disabled:          rgba(black, 0.38),
      disabled-button:   rgba(black, 0.38),
      disabled-text:     rgba(black, 0.38),
      hint-text:         rgba(black, 0.38),
      secondary-text:    $grey-text,
      icon:              rgba(black, 0.54),
      icons:             rgba(black, 0.54),
      text:              rgba(black, 0.87),
      slider-min:        rgba(black, 0.87),
      slider-off:        rgba(black, 0.26),
      slider-off-active: rgba(black, 0.38),
      app-bar:           $app-bar-color
    ),
    background: (
      status-bar: map_get(mat.$grey-palette, 300),
      app-bar:    $app-bar-background,
      background: map_get(mat.$grey-palette, 50),
      hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
      card:       white,
      dialog:     white,
      disabled-button: $black-12-opacity,
      raised-button: white,
      focused-button: $black-6-opacity,
      selected-button: map_get(mat.$grey-palette, 300),
      selected-disabled-button: map_get(mat.$grey-palette, 400),
      disabled-button-toggle: map_get(mat.$grey-palette, 200),
      unselected-chip: map_get(mat.$grey-palette, 300),
      disabled-list-option: map_get(mat.$grey-palette, 200),
    )
  );
}
