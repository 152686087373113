@use '@angular/material' as mat;
@mixin portal-error-404-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);

  .portal-error-404 {
    h1 {
      color: mat.get-color-from-palette($accent, default);
    }
    mat-card-content {
      background-color: mat.get-color-from-palette($background, card);
    }
    &:before {
      color: rgba(mat.get-color-from-palette($accent, default-contrast), .1);
    }
  }
}
