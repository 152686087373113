/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "app/common/styles/styles";

/* 3rd Party CSS */
$fa-font-path: "~font-awesome/fonts/";
@import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/highlight.js/styles/monokai-sublime.css";

/* Portal CSS */
@import "sass/portal";

/* Angular Material */
// @import "@angular/material/theming";

/* Flex */
@import "sass/flex";

@import "sass/material3-capability";

/* Typography */
$app-typography: mat.define-typography-config(
  $font-family: "Roboto, sans-serif",
  // $headline-1: mat.define-typography-level(112px, 112px, 300),
  // $headline-2: mat.define-typography-level(56px, 56px, 400),
  // $headline-3: mat.define-typography-level(45px, 48px, 400),
  // $headline-4: mat.define-typography-level(34px, 40px, 400),
  // $headline: mat.define-typography-level(24px, 32px, 400),
  // $title: mat.define-typography-level(20px, 32px, 500),
  // $subtitle-1: mat.define-typography-level(16px, 24px, 400),
  // $subtitle-2: mat.define-typography-level(14px, 24px, 400),
  // $body-2: mat.define-typography-level(14px, 24px, 400),
  // $body-1: mat.define-typography-level(14px, 24px, 400),
  // $caption: mat.define-typography-level(12px, 20px, 400),
  // $button: mat.define-typography-level(16px, 22px, 500),
  // // Line-height must be unit-less fraction of the font-size.
  // $input: mat.define-typography-level(16px, 1.5, 400)
);

// Set up typography with above rules
// TODO(v15): As of v15 mat.core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.typography-hierarchy($app-typography);`
@include mat.all-component-typographies($app-typography);
@include mat.core();

$app-primary: mat.define-palette($aomi-blue);
$app-accent: mat.define-palette($aomi-grey);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    )
  )
);

// Create app styles
@include mat.all-component-themes($app-theme);
@include portal-theme($app-theme, $app-typography);

// Create the theme object (a Sass map containing all of the palettes).
$sidenav-theme: portal-light-theme($app-primary, $app-accent, $app-warn);

.portal-sidenav-theme {
  @include portal-theme($sidenav-theme, $app-typography);
}

.mat-checkbox-frame {
  border-color: $divider;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $divider;
}

mat-spinner {
  circle {
    stroke: rgba(255, 255, 255, 1) !important;
  }
}

mat-form-field {
  width: 100%;
}
