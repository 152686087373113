@import 'consts/colors';

.gl-page-content {
  position: relative;
  padding: 16px;
}

.gl-box-shadow {
  box-shadow: 0px 2px 6px rgba(135, 145, 180, .2), 0px 1px 10px rgba(135, 145, 180, .14), 0px 1px 40px rgba(135, 145, 180, .12);
}

.gl-page-content-card {
  @extend .gl-box-shadow;
  border-radius: 6px;
  width: 100%;
  background-color: $white;
}

.gl-table-row {
  &:last-child {
    mat-row {
      border-bottom: 0;
    }
  }

  mat-row {
    min-height: 52px;
  }

  @at-root {
    a#{&} {
      text-decoration: none;
      transition: 200ms;

      &:hover {
        background-color: $bg-light-grey;
      }
    }
  }
}

.gl-w-300 {
  width: 300px;
  min-width: 300px;
}

.gl-mb-8 {
  margin-bottom: 32px;
}

.gl-preview {
  &-card {
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 2px 6px rgba(135, 145, 180, 0.2),
                0 1px 10px rgba(135, 145, 180, 0.14),
                0 1px 40px rgba(135, 145, 180, 0.12);
  }

  &-volume {
    position: absolute !important;
    bottom: 10px;
    right: 10px;
    cursor: default;
  }
}

.gl-curve-wrapper {
  margin-bottom: 8px;

  &.with-hiragana {
    margin-bottom: 24px;
  }
}
