mat-nav-list .mdc-list-item__primary-text {
    display: flex;
    align-items: center;

    h3 {
        margin-left: 16px;
        margin-bottom: 0;
    }
}

.mat-mdc-text-field-wrapper {
    background-color: transparent !important;

    input {
        max-width: 90%;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
    }
}

.mat-mdc-icon-button {
    &.mat-mdc-button-base {
        padding: 0 !important;
        padding-top: 2.5px !important;
        width: 30px !important;
        height: 30px !important;
    }
}

.mat-icon {
    overflow: unset !important;

    svg {
        width: 25px;
        height: 25px;
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__container {
        app-confirm-dialog {
            .mat-mdc-dialog-title {
                padding-top: 24px;
            }

            .mat-mdc-dialog-actions {
                padding-bottom: 24px;
            }
        }
    }
}
